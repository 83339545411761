import React, { useState, useEffect } from 'react';

import LoaderDecora from '@components/LoaderDecora';
import { ComponentsProvider, theme } from '@printi/printi-components';
import { wrapper } from '@store';
import { api } from '@utils';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function MyApp({ Component, pageProps }) {
  const [locale, setLocale] = useState();
  const [localeError, setLocaleError] = useState(false);
  const [localeLoading, setLocaleLoading] = useState(true);

  useEffect(() => {
    const { CancelToken } = api;
    const source = CancelToken.source();

    const fetchLocale = async () => {
      setLocaleLoading(true);
      await api('/v2/locale', {
        cancelToken: source.token,
      })
        .then(({ data }) => setLocale(data))
        .catch(() => setLocaleError(true));
      setLocaleLoading(false);
    };

    if (!locale && !localeError) {
      fetchLocale();
    }

    return () => {
      source.cancel();
    };
  }, [locale]);

  return (
    <ComponentsProvider theme={theme}>
      {localeLoading ? <LoaderDecora kind="light" /> : <Component {...pageProps} />}
    </ComponentsProvider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default wrapper.withRedux(MyApp);
