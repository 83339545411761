const saveTemplate = () => global.designer.saveDocumentToUds();

const getPreview = () => {
  const preview = {
    size: {
      width: 1200,
    },
    page: 1,
  };

  return global.designer.preview.getPreview(preview);
};

const switchProduct = (config) => global.designer && global.designer.switchProduct(config);

const start = (config) => global.designer && global.designer.start(config);

const isCanvasesEmpty = () => {
  const canvases = global.designer.documentRepository.getAllCanvasViewModels();
  let result = true;
  let qty = 0;
  canvases.forEach((canvas) => {
    if (canvas.itemViewModels.length > 0) {
      qty += 1;
    }
  });

  if (qty >= canvases.length) {
    result = false;
  }

  return result;
};

const getPreviewUrl = () => global.designer && global.designer.api.design.preview.getPreviewUrl();

export default {
  start,
  switchProduct,
  getPreview,
  saveTemplate,
  isCanvasesEmpty,
  getPreviewUrl,
};
