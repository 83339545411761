export const handlePageView = (pagePath) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
      virtualUrl: pagePath,
    });
  }
};

export const setDataLayerEvent = (eventName) => {
  if (global.dataLayer) {
    global.dataLayer.push({
      eventName,
    });
  }
};
