import { combineReducers } from 'redux';

import cookieConsentPolicy from './cookieConsentPolicy/reducer';
import customerServiceInfo from './customerServiceInfo/reducer';
import footerLinks from './footerLinks/reducer';

export default combineReducers({
  cookieConsentPolicy,
  footerLinks,
  customerServiceInfo,
});
