import React, { memo, useMemo } from 'react';

import { Image } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './styles';

const LoaderDecora = ({ kind, size }) => {
  const loaderPath = useMemo(() => (kind === 'light' ? 'light.gif' : 'dark.gif'), [kind]);

  return (
    <S.ContainerLoader size={size}>
      <Image layout="responsive" alt="Carregando" src={`../../loaderDecora/${loaderPath}`} />
    </S.ContainerLoader>
  );
};

LoaderDecora.propTypes = {
  kind: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.string,
};
LoaderDecora.defaultProps = {
  kind: 'light',
};

export default memo(LoaderDecora);
