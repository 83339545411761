export const assertValidEmail = (value) => {
  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !regexEmail.test(String(value).toLowerCase()) ? 'Endereço de email inválido' : '';
};

export const assertMatchFields = (value, matchValue) => (value !== matchValue ? 'Os dois campos devem ser iguais' : '');

export const assertMinlength = (value, min) =>
  value.length < Number(min) ? `Deve conter no mínimo ${min} caracteres` : '';

export const assertMaxlength = (value, max) =>
  value.length > Number(max) ? `Deve conter no máximo ${max} caracteres` : '';

export const assertRequiredField = (value) => (!value ? 'Este campo é obrigatório' : '');

export const assertStrongPassword = (value) => {
  const hasUpperCase = /[A-Z]/.test(value); // Checks if any upper-case letter/alphabhet [A-Z] is present
  const hasLowerCase = /[a-z]/.test(value); // Checks if any lower-case letter/alphabhet [a-z] is present
  const hasNumbers = /\d/.test(value); // Checks if any digit [0-9] is present
  const hasNonAlphaNumeric = /\W/.test(value); // Checks characters other than [A-Za-z0-9_] i.e. Special Characters

  return hasUpperCase + hasLowerCase + hasNumbers + hasNonAlphaNumeric < 4
    ? 'A senha deve conter no mínimo uma letra maiúscula, uma minúscula, um número e um caracter especial'
    : '';
};
