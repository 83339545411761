const INITIAL_STATE = {
  footerLinks: undefined,
  loading: false,
  isLoaded: false,
  error: '',
};

const footerLinks = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@footerLinks/LOAD_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@footerLinks/LOAD_SUCCESS':
      return {
        ...state,
        footerLinks: action.footerLinks,
        loading: false,
        isLoaded: true,
      };
    case '@footerLinks/LOAD_FAILURE':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        isLoaded: true,
      };

    default:
      return state;
  }
};

export default footerLinks;
