import styled, { css } from 'styled-components';

const getSize = (p) => {
  if (p.size === 'small') {
    return css`
      width: 100%;
      height: 10vh;
    `;
  }
  if (p.size === 'medium') {
    return css`
      width: 100%;
      height: 20vh;
    `;
  }
  return css`
    width: 100%;
    height: 100vh;
  `;
};

export const ContainerLoader = styled.div`
  background-color: #edf1f7;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  ${(p) => getSize(p)};

  img {
    width: 8rem;
    height: 8rem;
    margin: 0 auto;

    position: absolute;
  }
`;
